import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import globals from "../components/globals"
import ContentAndPicture from "../components/content-and-picture"
import { Container, P } from "../components/styledcomponents"
import TrustBox from "../components/trusbox"

import Icons from "../components/icons"

const Icon = styled(Icons)`
  height: 0.8em;
  color: ${globals.color.main};
  position: relative;
  ${(props) => (props.left ? "bottom: 0.5em" : "top: 0.5em")};
  padding: 0 0.3em;
`

const Blockquote = styled.blockquote`
  max-width: ${globals.media.mobile};
  margin: auto;
  line-height: 150%;
`

const TraumaInformed = ({ data }) => (
  <Layout>
    <SEO
      title="Trauma Informed Yoga und Selbstverteidigung"
      description="Trauma informierte Bewegungstherapie durch Yoga und \
    Selbstverteidigung aktiviert das parasympathische Nervensystem und \
    reduziert Stress."
    />
    <h1>Trauma Informed Yoga und Selbstverteidigung</h1>

    <Blockquote>
      <p>
        <Icon icon="quoteLeft" />
        Fight or flight can be a great tool when you’re in danger because it
        amps you up to battle through or sprint from trouble, but it’s no way to
        live
        <Icon icon="quoteRight" left />
      </p>
      <cite>David Goggins</cite>
    </Blockquote>

    <ContentAndPicture
      h2="Fight, Flight und Freeze"
      description={
        <div>
          <P>
            Heutzutage leben zu viele Kinder und Erwachsene in einem konstanten
            ‚fight, flight oder freeze’ Zustand. In psychologischen Terminologie
            sagt man:&nbsp;‚das sympathisches Nervensystem ist hyperaroused’.
            Dieser Zustand wird durch Traumas, wie Flucht wegen Krieg oder
            Naturkatastrophen, Missbrauch, Mobbing, Unfälle, und so
            weiter,&nbsp;verursacht. Jemand der sich kontinuierlich unsicher und
            verletzlich fühlt, tut sich schwer sich zu entspannen und damit zu
            heilen.
          </P>
        </div>
      }
      imgLeft={data.atem}
      alt="Atemübungen helfen das parasympathische Nervensystem zu aktivieren und damit Stress zu reduzieren"
    />

    <ContentAndPicture
      h2="Unterstütztung des Heilungsprozesses durch Traumasensitive Ansätze"
      description={
        <div>
          <P>
            Trauma sensitive Ansätze, zum Beispiel in Form von{" "}
            <Link to="/yoga-muenchen/">Yoga</Link> und{" "}
            <Link to="/kampfsport-in-muenchen/">Selbstverteidigung</Link>,
            unterstützt den Heilungsprozess. 'Trauma informed care' ist in den
            USA und Australien&nbsp;bekannt, aber steckt in Europe noch in den
            Kinderschuhen. In den USA gibt es mittlerweile&nbsp;‚trauma informed
            schools’,&nbsp;‚trauma informed yoga’&nbsp;für Polizei, Feuerwehr
            und in Australien vor allem für Flüchtlinge.
          </P>
        </div>
      }
      imgRight={data.backbend}
      alt="Yoga Backbends öffnen den Körper und bringen damit Körper und Geist näher zusammen."
    />
    <ContentAndPicture
      h2="Warum sind Yoga und Selbstverteidigung besonders effektive Methoden?"
      description={
        <div>
          <P>
            Durch verschiedene Forschungen von Trauma-Experten, wie Bessel von
            der Kolk (<em>The Body keeps Score&nbsp;</em>2005) und Peter Levine
            (
            <em>
              In an Unspoken voice: How the body releases Trauma and restores
              Goodness&nbsp;
            </em>
            2010), wissen wir mittlerweile, dass bei traumatisierten Personen
            Körper und Seele in gewissen Maßen und Bereichen von einander
            abgeschnitten sind. Trauma ist holistisch im dem Sinne, dass es
            sowohl den Körper als auch die Seele betrifft. Das bedeutet, dass
            wir manchmal nicht zum Kern des Traumas durchdringen können wenn wir
            nur, zum Beispiel, mit ein TherapeutIn reden. Unsere ‚rationales
            Hirn’ hat häufig kein Zugang zum dem Teil des Hirns, in dem&nbsp;das
            Trauma verankert ist. Bewegung, auf der anderen Seite, hat diesen
            Zugang oft.
          </P>
        </div>
      }
      imgLeft={data.partner}
      alt="Partneryoga erweitert das 'Window of Tolerance' und bildet Vertrauen zu anderen Menschen."
    />

    <ContentAndPicture
      h2="Yoga und Selbstverteidigung als Bewegungstherapie"
      description={
        <div>
          <P>
            Deswegen implementieren viele Therapeuten und Psychologen
            Bewegungstherapien wie Tanzen, Yoga und auch Selbstverteidigung, zum
            Unterstützung der generellen Therapie. Bewegungstherapien helfen
            dabei, Körper und Geist Schritt für Schritt wieder naher zusammen zu
            bringen und die Verbindung wieder herzustellen. Wenn dies geschieht,
            ist man in eine bessere Lage zu heilen. Zudem hat man Möglichkeiten
            sich selbst weiter zu helfen, wenn ein Therapeut/Psycholog nicht
            anwesend ist/sein kann, denn, heilen von Trauma ist niemals ein
            linearer Prozess.
          </P>
          <P>
            Yoga und Selbstverteidigung sind sehr effektive und sichere
            Methoden, da man sich in einer sicheren Umgebung körperlich und in
            Folge auch geistig entspannen und auspowern kann. Dadurch kann man
            auch das ‚window of Tolerance’ erweitern. Durch bestimmte Atmungs-,
            Bewegungs- und Haltungsübungen wird das parasympathische
            Nervensystem aktiviert und man kommt aus diesem ‚fight oder flight’
            Zustand heraus. Speziell bei der Selbstverteidigung lernt
            man&nbsp;durch ‚Exposure Therapy’&nbsp;widerstandsfähiger und
            selbst-sicherer zu sein. Schritt für Schritt geht der/die
            TeilnehmerIn durch in Szene gesetzte stressigere Situationen und
            lernt besser damit umzugehen.
          </P>
        </div>
      }
      imgRight={data.bewegung}
      alt="Shavasana ist eine der besten Yoga-Übungen um das parasympathische Nervensystem zu aktivieren."
    />
    <ContentAndPicture
      h2="Unsere Therapiehündin Yuki (雪, Japanisch für Schnee)"
      description={
        <div>
          <P>
            Yuki ist ein englische Springer Spaniel und im Mai 2020 geboren, sie
            ist also noch ein Welpe. Trotz Ihrer Jugend ist sie eine
            Therapiehündin in Ausbildung, und spielt jetzt schon und wird noch
            eine sehr wichtige Rolle in TLA spielen.
            <Blockquote>
              <P>
                <Icon icon="quoteLeft" />
                Therapiehunde sind Hunde, die in Familien, bei Privatpersonen
                und/oder in Institutionen im Dienste der Gesundheit,
                Resozialisierung und Rehabilitation eingesetzt werden.
                <Icon icon="quoteRight" left /> -{" "}
                <cite>
                  <a
                    href="https://www.dbtb.info/ausbildung/ausbildung-zum-therapiehunde-team/"
                    rel="nofollow"
                  >
                    DBTB e.V.
                  </a>
                </cite>
              </P>
            </Blockquote>
            Bestimmte Hunde haben die Fähigkeit zu spüren, wenn jemand, ein
            Kind, Jugendliche oder Erwachsene, sich schlecht fühlt, traurig ist,
            Schmerzen hat, eine Panikattacke hat, hyperventiliert oder sich
            (wegen z.b. Trauma) dissoziiert. Sie spüren es viel schneller als
            wir Menschen es erkennen können. Zudem können Therapiehunden den
            Genesungsprozess mit ihrer Anwesenheit positiv beeinflussen.
            Deswegen ist eine Therapiehündin eine Bereicherung unseres Teams!
            Bereits jetzt hat Yuki viele Kinder (die z.b. gemobbt wurden,
            traumatisiert sind und Verhaltensprobleme haben) geholfen sich in
            die Gruppe zu integrieren, Kinder die sich weh getan haben sofort
            getröstet (sie will dann unbedingt zum Kind und lässt sich sofort
            auf ihren Rücken fallen) und gestresste Jugendliche und Erwachsene
            zu Ruhe gebracht.
          </P>
        </div>
      }
      imgLeft={data.yuki}
    />
    <ContentAndPicture
      h2="The Yoga Impact Charity und Team Laurien &amp; Alex"
      description={
        <div>
          <P>
            Team Laurien &amp; Alex arbeitet mit der The Yoga Impact Charity
            unter Yogalehrerin und Psychologin Danielle Begg&nbsp;zusammen.
            Danielle war Laurien’s erste Yogalehrerin und hatte den meisten
            Einfluss. In 2015 gründete Danielle ein non-profit Organisation,{" "}
            <a href="http://www.yogaimpactcharity.com">
              The Yoga Impact Charity
            </a>
            . Diese Organisation bietet Weltweit wissenschaftlich fundierte
            trauma-informierte Yoga-Kurse für Flüchtlingen (Kinder und
            Erwachsene), Polizei, Feuerwehr und Streitkräfte an und arbeitet mit
            anderen gemeinnützigen Organisationen, Schulen und Behörden
            zusammen. In Jahr 2018 boten wir 417 Kurse für 3000 Personen an 16
            Standorten an.
          </P>
          <P>
            Der NSW-Service for the Treatment and Rehabilitation of Torture and
            Trauma Survivors&nbsp; (STARTTS) hat unser Yoga-Programm für
            Flüchtlinge evaluiert und konnte feststellen, dass die Teilnahme an
            Yoga-Kursen zur Reduktion von PTBS-Symptomen und Depressionen
            führte. Teilnehmer berichteten auch von einer Verminderung
            traumabedingter Angstgefühle sowie von besserem Schlaf, einem
            gestärkten Zugehörigkeitsgefühl und reduziertem körperlichem
            Schmerz. Die Evaluationsergebnisse wurden im vergangenen Jahr auf
            der North American Refugee Health Conference und der Australia and
            New Zealand Refugee Trauma Conference in Sydney vorgestellt.
          </P>
        </div>
      }
      imgRight={data.danielle}
      alt="Danielle Begg von der Yoga Impact Charity"
    />

    <ContentAndPicture
      h2="Zertifizierung durch The Yoga Impact Charity"
      description={
        <P>
          Alle (Kinder-)Yogalehrer, die bei The Yoga Impact Charity arbeiten,
          wie Alex &amp; Laurien auch, sind mindestens in 200h Yoga zertifiziert
          und haben eine zusätzliche Ausbildung bei The Yoga Impact Charity
          absolviert, um ihre fachliche Kompetenz im Umgang mit traumatisierten
          Yoga-Schülern zu vertiefen und den trauma-informierten Unterricht
          angemessen und effektiv zu gestalten.&nbsp;
        </P>
      }
    />

    <Container backgroundColor="transparent" padding="4em 0" />
    <TrustBox
      title="Unser Yoga- und Kampfsportangebot überzeugt:"
      values={[
        {
          id: 1,
          img: data.trust1,
          trust: "Kampfsportschule des Jahres 2020",
        },
        {
          id: 5,
          img: data.trust5,
          trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
        },
        {
          id: 3,
          img: data.trust3,
          trust: "National und International erfolgreich",
        },
        {
          id: 6,
          img: data.trust6,
          trust: "BJJ Graduierung duch Cadu Francis",
        },
        {
          id: 4,
          img: data.trust4,
          trust: "Begeisterte Kunden",
        },
        {
          id: 2,
          img: data.trust2,
          trust: "IBJJF Zertifiziert",
        },
        {
          id: 7,
          img: data.trust7,
          trust: "Zertifiziert durch Kidding Around Yoga",
        },
      ]}
    />
  </Layout>
)

export default TraumaInformed

export const query = graphql`
  {
    atem: file(
      relativePath: {
        eq: "Trauma/Trauma-sensitive-yoga-selbstverteidigun-Atemuebung-1024x768.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    backbend: file(
      relativePath: {
        eq: "Trauma/Trauma-sensitive-yoga-selbstverteidigung-muenchen-gentle-back-bend-1-1024x768.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    partner: file(
      relativePath: {
        eq: "Trauma/Trauma-sensitive-yoga-selbstverteidigung-partneryoga-1024x768.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    bewegung: file(
      relativePath: {
        eq: "Trauma/Trauma-sensitive-yoga-selbstverteidigung-muenchen-shavasana-1024x1024.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    yuki: file(relativePath: { eq: "Trauma/Therapiehund-Yuki-TLA.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    danielle: file(
      relativePath: {
        eq: "Trauma/Danielle-Begg-Yoga-impact-charity-1024x681.jpeg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust1: file(
      relativePath: { eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust2: file(relativePath: { eq: "IBJJF-Zerifikat-TLA-BJJ.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust3: file(
      relativePath: { eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust5: file(
      relativePath: {
        eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust4: file(relativePath: { eq: "Reviews-Team-Laurien-Alex.JPG" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust6: file(
      relativePath: {
        eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    trust7: file(relativePath: { eq: "Kinderyoga-Zertifikat.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 450
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
